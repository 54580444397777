import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import axios from 'axios';
import LoginPage from './pages/LoginPage.js'
import PanelPage from './pages/PanelPage.js'

var apiAdress = "http://localhost:2626";
export default App;

axios.interceptors.request.use(
  config => {
    const { origin } = new URL(config.url);
    const allowedOrigins = [apiAdress];
    const token = localStorage.getItem('accessToken');
    if (allowedOrigins.includes(origin)) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
            <Route path="/login" element={<LoginPage/>}></Route>
            <Route path="/" element={<LoginPage/>}></Route>
            <Route path="/panel" element={<PanelPage/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}
