import axios from 'axios';
import { React, Component } from 'react';
import '../styles/PanelPage.css';
import {Link, Navigate} from "react-router-dom";
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import apiAddress from '../context';

Modal.setAppElement('#root');

export default class PanelPage extends Component {
    constructor () {
        super();
        this.state = {
            showModal: false,
            logOut: <></>,
            loadingPatreon: <></>,
            checkLogged: false,
            user:  JSON.parse(localStorage.getItem("user")),

            'packs': [
            {
                id: 51651651,
                name: "Dartcher's Pack partial",
                downloadLink: "https://dartcher.com/dl/0/file.dnxpack",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                protectedTo: "",
                image: 'https://picsum.photos/500',
                contents: ["Voiture", "Camion", "Armure"]
            },
            {
                id: 548995633,
                name: "DynamX's Pack",
                downloadLink: "https://dartcher.com/dl/0/file.dnxpack",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                protectedTo: ".DynamX",
                image: 'https://picsum.photos/400',
                contents: ["Voiture", "Camion", "Armure"]
            },
            {
                id: 898789155,
                name: "Dartcher's pack FULL ACCESS",
                downloadLink: "https://dartcher.com/dl/0/file.dnxpack",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                protectedTo: ".None",
                image: 'https://picsum.photos/600',
                contents: ["Voiture", "Camion", "Armure"]
            }
        ]
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal () {
        this.setState({ showModal: true });
    }
      
    handleCloseModal () {
        this.setState({ showModal: false });
    }

    logOut() {
        localStorage.setItem('accessToken', "logout");
        localStorage.setItem('refreshToken', "logout");
        this.setState({
            logOut: <><Navigate to="/"/></>
        })
    }

    hasBeenCheck = false;
    checkLoginToRedirect() {
        if (this.hasBeenCheck === false) {
            this.hasBeenCheck = true;
            const req = axios.get(`${apiAddress}/user/me`)
            // eslint-disable-next-line no-unused-vars
            const user = req.then((usr) => {
                localStorage.setItem("user", JSON.stringify(usr.data));
                this.setState({loadingPatreon: <></>})
                this.setState({checkLogged: true})
            }, (err) => {
                this.setState({checkLogged: true})
                this.setState({loadingPatreon: <><Navigate to="/"/></>})
            })
            
        }
        return (<>{this.state.loadingPatreon}</>)
    }

    modalContent() {
        return (
            <div className="modal-content">
                <p className='warning'>
                    For reasons of download and forbidden distributions ".minecraft" is not supported
                </p>
                Before you can download your pack you must indicate the root folder of your game.
                <p>Examples 1:</p>
                For <span>C:\Users\Dartcher\AppData\Roaming\.sqript</span>
                <a target="_blank" rel='noreferrer' href={window.location.origin + '/img/example1.png'}>
                    <img alt='example 1' src={window.location.origin + '/img/example1.png'}></img>
                </a>
                You must put <span>sqript</span>
                <p>Examples 2:</p>
                For <span>[...]\AppData\Roaming\.minecraft\modpacks\DynamxBeta</span>
                <a target="_blank" rel='noreferrer' href={window.location.origin + '/img/example2.png'}>
                    <img alt='example 2' src={window.location.origin + '/img/example2.png'}></img>
                </a>
                You must put <span>DynamxBeta</span>
                <p>Examples 3:</p>
                For <span>[...]\AppData\Roaming\.technic\modpacks\dynamx-official</span>
                <a target="_blank" rel='noreferrer' href={window.location.origin + '/img/example3.png'}>
                    <img alt='example 3' src={window.location.origin + '/img/example3.png'}></img>
                </a>
                You must put <span>dynamx-official</span>
            </div>
        )
    }

    render() {
        return (
            <div className="panel">
                {this.checkLoginToRedirect()}

                <ReactModal 
                    isOpen={this.state.showModal}
                    contentLabel="onRequestClose Example"
                    onRequestClose={this.handleCloseModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    >
                    <div className="modal-container">
                        <div className="modal-top">
                            Options:
                            <div className="modal-close" onClick={this.handleCloseModal}></div>
                        </div>
                        {this.modalContent()}
                        <div className="modal-bottom">
                            <input type="text" placeholder="folder of your game (without the ' . ')"></input>
                            <div className="btn-valid" onClick={this.handleCloseModal}>Confirm</div>
                        </div>
                    </div>
                </ReactModal>

                <div className='logout-btn' onClick={() => {this.logOut()}}>
                    Log Out from {this.state.user.username} {this.state.logOut}
                </div>

                <div className='list-packages'>
                    {this.state.packs.map(pack => {
                        return (
                            <div key={pack.id} className="package-element">
                                <div className='pack-top'>
                                    <img alt={pack.name} src={pack.image}></img>
                                    <div className='pack-top-info'>
                                        <h2>
                                            {pack.name}
                                        </h2>
                                        <div className="btn-option-pack" onClick={this.handleOpenModal}></div>
                                    </div>
                                </div>
                                <div className='pack-middle'>
                                    <div className='content-middle'>
                                        {pack.description}
                                    </div>
                                    <div className='content-middle'>
                                        Includes:
                                        {pack.contents.map(element => {
                                            return (
                                            <div key={element} className='ls-tab'>
                                                •	{element}
                                            </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='pack-bottom'>
                                    {pack.protectedTo === "" ?
                                    <div className="btn-bottom-dl" onClick={this.handleOpenModal}>
                                        <img alt='download' src={window.location.origin + '/edit.svg'}></img>
                                        Edit
                                    </div> :
                                    <Link style={{ textDecoration: 'none'}} to={pack.downloadLink} target="_blank" download>
                                        <div className="btn-bottom-dl">
                                            <img alt='download' src={window.location.origin + '/download.svg'}></img>
                                            Download
                                        </div>
                                    </Link>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}