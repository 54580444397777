import axios from 'axios';
import { React, Component } from 'react';
import { validate } from 'react-email-validator';
import '../styles/LoginPage.css';
import apiAdress from '../context';
import { Navigate } from "react-router-dom";

//TODO Refresh du token, protection du token
//TODO Version mobile

export default class LoginPage extends Component { 
  constructor(props) {
    super(props);
    this.state = {
        register: false,
        error: false,
        errorMsg: "",
        userCreated: false,
        isLogged: false,
        
        storedJwt: localStorage.getItem('accessToken'),
        refreshJwt: localStorage.getItem('refreshToken'),
        
        id: "",
        email: "",
        username: "",
        password: "",
        checkpassword: "",

        loadingPatreon: <div className='loadingPatreon'> Loading... </div>,
        firstCallPatreon: false,
        checkLogged: false,
        }
    this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.id]: event.target.value})
    }

    throwErr(msg) {
        this.setState({error: true, errorMsg: msg})
    }

    checkForm(type) {
        if (type ==="login") {
            if (this.state.id === "") {
                this.throwErr("Invalid ID")
            } else if (this.state.password === "") {
                this.throwErr("Invalid password")
            }
            else {
                let req = {};

                if(validate(this.state.id)) {
                    req = {
                        "email": this.state.id,
                        "password": this.state.password
                    }
                } else {
                    req = {
                        "username": this.state.id,
                        "password": this.state.password
                    }
                }

                axios.post(`${apiAdress}/auth/login`, req).then((result, err) => {
                    if(result.data.accessToken) {
                        this.setState({
                            isLogged: true
                        })
                        //TODO Convertir ce système vers -> https://medium.com/@ryanchenkie_40935/react-authentication-how-to-store-jwt-in-a-cookie-346519310e81
                        localStorage.setItem('accessToken', result.data.accessToken);
                        localStorage.setItem('refreshToken', result.data.refreshToken);
                    } else {
                        this.throwErr(result.data)
                    }
                })
            }
        }
        if (type === "register") {
            if (!validate(this.state.email)) {
                this.throwErr("Invalid email format")
            } else if (this.state.username === "") {
                this.throwErr("Invalid Username")
            } else if (this.state.password.length <= 7) {
                this.throwErr("Your password must have at least 8 characters")
            } else if (!(this.state.password === this.state.checkpassword)) {
                this.throwErr("Your passwords are not exactly the same")
            }
            else {
                axios.post(`${apiAdress}/auth/register`, {
                    "email": this.state.email,
                    "username": this.state.username,
                    "password": this.state.password
                }).then((result) => {
                    if(result.data === 'User created') {
                        this.setState({userCreated: true, password: ""})
                    } else {
                        this.throwErr(result.data)
                    }
                })
            }
        }
    }

    loginFormContent() {
        if (this.state.isLogged) {
            if (this.state.firstCallPatreon === false) {
                this.setState({firstCallPatreon: true})
                axios.get(`${apiAdress}/user/packages`).then((result, err) => {
                    if (err) {
                        this.throwErr(err)
                    } else {
                        if (result.data === "PatreonId null") {
                            this.setState({
                                loadingPatreon:
                                    <a href="https://www.patreon.com/oauth2/authorize?response_type=code&client_id=Q2mGk8BPm14VugZZNGh5C5LsagXBWP4CfUDPiCvXXPU5z6wZBSVZ4IGGfEXv_sZV&redirect_uri=http://localhost:3000/" style={{textDecoration: "none"}}>
                                        <button className="patreon-btn log-with-p">Link my patreon</button>
                                    </a>
                            })
                        }
                        else if (result.data !== "PatreonId null") {
                            this.setState({
                                loadingPatreon:
                                <>
                                    <Navigate to="/panel" />
                                </>
                            })
                           
                        }
                    }
                })
            }
            return (
                <div className="lgc-login">
                    {this.state.loadingPatreon}
                </div>
            )
        }
        else if (this.state.register) {
            return(
                <div className="lgc-login">
                    <div className="lgc-form">
                        <h3>Register:</h3>

                        {this.state.error ? <div className="mg-b lgc-error">
                            {this.state.errorMsg}
                            <div className="errorCross" onClick={() => {
                                this.setState({error: false})
                            }}>
                                x
                            </div>
                        </div> : <></>}

                        <input className="mg-b" id="username" type="text" value={this.state.username} onChange={this.handleChange} placeholder="Username..."></input>
                        <input className="mg-b" id="email" type="text" value={this.state.email} onChange={this.handleChange} placeholder="Email..."></input>
                        <input className="mg-b" id="password" type="password" value={this.state.password} onChange={this.handleChange}  placeholder="Password"></input>
                        <input className="mg-b" id="checkpassword" type="password" value={this.state.checkpassword} onChange={this.handleChange} placeholder="Repeat password"></input>
                        <div className="chg-st" onClick={() => {
                            this.setState({register: !this.state.register})
                        }}>You already have an account ?</div>
                        <button className="patreon-btn" onClick={() => {
                            this.checkForm("register")
                        }}>Register</button>
                    </div>
                </div>
            )
        }
        else if (!this.state.register) {
            return (
                <div className="lgc-login">
                    <div className="lgc-form">
                        <h3>Login:</h3>

                        {this.state.error ? <div className="mg-b lgc-error">
                            {this.state.errorMsg}
                            <div className="errorCross" onClick={() => {
                                this.setState({error: false})
                            }}>
                                x
                            </div>
                        </div> : <></>}

                        <input className="mg-b" id="id" type="text" value={this.state.id} onChange={this.handleChange} placeholder="Username or Email"></input>
                        <input className="mg-b" id="password" type="password" value={this.state.password} onChange={this.handleChange} placeholder="Password"></input>
                        <div className="chg-st" onClick={() => {
                            this.setState({register: !this.state.register})
                        }}>You don't have an account ?</div>
                        <button className="patreon-btn" onClick={() => {
                            this.checkForm("login")
                        }}>Log In</button>
                    </div>
                </div>
            )
        }
    }

    checkLoginToRedirect() {
        var user;
        const code = new URLSearchParams(window.location.search).get('code');


        if (code) {
            axios.post(`${apiAdress}/user/patreonid`, {patreonId: code}).then(() => {
                this.setState({loadingPatreon: <><Navigate to="/panel"/></>}) 
            })
        }
        if (this.state.checkLogged === false) {
            this.setState({checkLogged: true})
            axios.get(`${apiAdress}/user/me`).then((result, err) => {
                if (err) {
                    console.error(err);
                } else {
                    user = result.data;
                    localStorage.setItem("user", JSON.stringify(user));
                    if (user.username && user.patreonId !== '') {
                        this.setState({loadingPatreon: <><Navigate to="/panel"/></>})
                    }
                }
            })
        }

        return (
            <>
                {this.state.loadingPatreon}
            </>
        )
    }

    render() {
        return(
            <div className="login-page">
                <div className="login-container">
                    <div className="lgc-left">
                        <div className="lgc-content">
                            <img alt="dartcher logo" src={window.location.origin + '/logo.png'}></img>
                            <h2>Dartcher's Pack</h2>
                            <p>Here you can get the packs from the Dartcher's patreon</p>
                        </div>
                    </div>
                    <div className="lgc-right">
                        <div className='lgc-login'>
                            {this.checkLoginToRedirect()}
                        </div>
                        {this.state.userCreated ? 
                            <div className="lgc-login">
                                <div className="lgc-form">
                                    <h3 className="mg-b usr-crtd">User {this.state.username} has been created !</h3>
                                    <button className="patreon-btn" onClick={() => {
                                        this.setState({userCreated: false, register: false})
                                    }}>Log in</button>
                                </div> 
                            </div> 
                        : this.loginFormContent()}
                    </div>
                </div>

                <div className="bg-lg">
                    <div className="bg-left"></div>
                    <div className="bg-right"></div>
                    <div className="bg-shadow"></div>
                </div>
            </div>
        )
    }
}